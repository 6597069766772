import Breadcrumb from './Breadcumb';
import EventsListing from './EventsListing';
import Header from './Header';
import Footer from './Footer';
function Events()
{
return (
<>
<Header />
<Breadcrumb title="Events" />

<EventsListing limit="100" />
<Footer />
   </>
);
}

export default Events;