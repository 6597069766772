import React, { useEffect, useState }from 'react';
import {Link, NavLink} from "react-router-dom";

function HeaderTransparent()
{


  const [member_login_status, setMember_login_status] = useState('');

  useEffect(() => {
    const member_login_status = localStorage.getItem('member_login_status');
    if (member_login_status) {
      setMember_login_status(member_login_status);
    }
  }, []);
  


return (
<>
<div className="header header-transparent change-logo">
    <div className="container">
      <nav id="navigation" className="navigation navigation-landscape row d-flex">
        <div className="nav-header my-auto col-md-2">
        <div className="nav-toggle"></div>
          <a className="nav-brand" href="/">
            <img src="/assets/images/yea-logo.png" className="logo" alt="" />
          </a>
          
          <div className="mobile_nav">
            <ul>
              <li>
                <a href="/dashboard" className="theme-cl fs-lg">
                  <i className="lni lni-user"></i>
                </a>
              </li>
             
            </ul>
          </div>
        </div>
        <div className="nav-menus-wrapper my-auto col-md-10" >
        <ul className="nav-menu">
            <li>
                <NavLink to='/' activeClassName="active" reloadDocument>Home</NavLink> 
            </li>
            <li>
              <NavLink to='/about-us' activeClassName="active" reloadDocument>About Us</NavLink>
            </li>
            <li>
                <NavLink to="/businesses" activeClassName="active" reloadDocument>Businesses</NavLink> 
            </li>
            <li>
                <NavLink to="/members" activeClassName="active" reloadDocument>Members</NavLink> 
            </li><li>
                <NavLink to="/events" activeClassName="active" reloadDocument>Events</NavLink> 
            </li>
           
            <li>
                <NavLink to="/contact-us" activeClassName="active" reloadDocument>Contact Us</NavLink> 
            </li>
            <li><a href="#">Resources</a>
									<ul className="nav-dropdown nav-submenu">
                    <li><NavLink to="/press-media" activeClassName="active" reloadDocument>Press & Media</NavLink></li>
                    <li><NavLink to="/startup-fund" activeClassName="active" reloadDocument>Startup Fund</NavLink></li>
                    <li><NavLink to="/event-gallery" activeClassName="active" reloadDocument>Event Gallery</NavLink></li>
                    <li><NavLink to='/terms-conditions' activeClassName="active" reloadDocument>Terms & Consitions</NavLink></li>
										<li><NavLink to='/privacy-policy' activeClassName="active" reloadDocument>Privacy Policy</NavLink></li>
                <li><NavLink to="/logout"><i className="lni lni-power-switch me-2" />Sign Out</NavLink></li>
                </ul>
</li>
          </ul>
          <ul className="nav-menu nav-menu-social align-to-right">           
            <li className="add-listing">
              { member_login_status === 0 || member_login_status === null ? (
                <Link to="/signin"><i className="fas fa-user me-2"></i> Signin</Link>
              ) : ( <Link to="/dashboard"><i className="fas fa-user me-2"></i> My Account</Link> )}
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>

   </>
);
}

export default HeaderTransparent;