import React, { useState, useEffect } from 'react'
import { getEvents } from "../services/event.service";
const BASE_API_URL = "https://yeaapi.mydigitalworks.online";

function EventsListing({limit})
{

  const [events, setEvents] = useState([]);
  useEffect(() => {
    const fetchEvents = async () => {
      const { data, error } = await getEvents();
      if (error) {
        console.log(error);
      } else {
        setEvents(data);
      }
    };
    fetchEvents();
  }, []);

return (
<>
<section className="ptb-30 gray">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div className="sec_title position-relative text-center mb-4">
        <h6 className="theme-cl mb-0">Events</h6>
        <h2 className="ft-bold">A Journey Through Years of Success</h2>
        <span className="animate-border mb-3"></span>
      </div>
    </div>
  </div>
  <div className="row justify-content-center"> 
    {events.slice(0,limit).map((data, index) => ( 
    <div className="single-list col-md-4 col-6 d-flex" key={index}>
      <div className="gup_blg_grid_box">
      
        <div className="gup_blg_grid_thumb">
          <a href={"/event/" + data.eventID}>
            <img src={BASE_API_URL + '/uploads/events/' + data.thumbnailImage} className="img-fluid" alt={data.title} />
          </a>
          <div className="Goodup-rating overlay">
            <div className="Goodup-pr-average mid">{data.eventStatus}</div>
					</div>
        </div>
        <div className="gup_blg_grid_caption text-center">
          <div className="blg_title">
            <h4>
              <a href={"/event/" + data.eventID}>{data.title}</a>
            </h4>
          </div>
        </div>
        <div className="crs_grid_foot">
									<div className="crs_flex d-flex align-items-center justify-content-center br-top px-3 py-2">
										
										<div className="crs_fl_last">
											<div className="foot_list_info">
												<ul>
													<li><div className="elsio_ic"><i className="fa fa-clock text-warning"></i></div><div className="elsio_tx">{data.start_date}</div></li>
												</ul>
											</div>
										</div>
									</div>
								</div>

      </div>
    </div> 
  ))} 
  { limit === '3' && (
<div className="text-center col-md-12 col-12" >
<a href="/events" className='btn btn-sm ft-medium text-light rounded theme-bg'>View More <i class="fas fa-caret-right mx-2"></i></a>
</div>
  )}
    </div>
</div>
</section>

</>
);
}

export default EventsListing;