import React, { useEffect } from 'react'

const Logout = () => {
  
    useEffect(() => {
      localStorage.setItem('member_login_status', 0);
      window.location.href="/signin";
    }, []);

}

export default Logout
