import axios from "axios";

export const getHomeSection2 = async () => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/homesection2/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export const getHomeSection2ById = async (id) => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/homesection2/" + id);
    return res.data
  } catch (err) {
    return {error: err.message}
  }
}



//-------------------//
export const getHomeSection3 = async () => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/homesection3/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}




export const getHomeSection3ById = async (id) => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/homesection3/" + id);
    return res.data
  } catch (err) {
    return {error: err.message}
  }
}


//===========//

export const getHomeSection4 = async () => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/homesection4/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}



export const getHomeSection4ById = async (id) => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/homesection4/" + id);
    return res.data
  } catch (err) {
    return {error: err.message}
  }
}




//===========//

export const getHomeSection5 = async () => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/homesection5/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}


export const getHomeSection5ById = async (id) => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/homesection5/" + id);
    return res.data
  } catch (err) {
    return {error: err.message}
  }
}


