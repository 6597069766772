import React, { useState, useEffect } from 'react'
import Breadcrumb from './Breadcumb';
import Header from './Header';
import Footer from './Footer';
import BusinessesListing from './BusinessesListing';

function Businesses()
{


return (
<>
<Header />
<Breadcrumb title="Business" />



<BusinessesListing />
<Footer />
   </>
);
}

export default Businesses;