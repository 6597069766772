import React from 'react';
import Breadcrumb from './Breadcumb';

import Header from './Header';
import Footer from './Footer';

function TermsConditions()
{

  
 

return (
<>
<Header />
<Breadcrumb title="Terms & Condition" />

<section className="ptb-30 gray">
    <div className="container">
   
      <div className="row align-items-center justify-content-between">
        <div className="col-lg-12 col-md-12 col-sm-12 content-column">
          <div className="content_block_2 pr-3 py-4">
            <div className="content-box">
              <div className="sec-title light">
                <h2 className="ft-bold">Terms &amp; Conditions</h2>
                <span className="animate-border mb-3"></span>
              </div>
              <div className="text mb-3 list-style-disc">
             
<p><strong>Effective Date:</strong> 15/10/24</p>

<p><strong>YEA Terms &amp; Conditions</strong></p>

<p>Welcome to YEA! These Terms &amp; Conditions outline the rules and regulations for the use of the YEA website and mobile application. By accessing or using our services, you agree to comply with these terms.</p>

<p><strong>1. Acceptance of Terms</strong></p>

<p>By registering with or using YEA&rsquo;s website or mobile app, you agree to be bound by these Terms &amp; Conditions and any policies referenced herein. If you do not agree, please discontinue the use of our services.</p>

<p><strong>2. Use of Services</strong></p>

<ul>
	<li>You agree to use the website and app for lawful purposes only.</li>
	<li>You are responsible for keeping your login credentials confidential and for any activity under your account.</li>
	<li>You must provide accurate and up-to-date information when creating or updating your account.</li>
</ul>

<p><strong>3. Membership and Business Profiles</strong></p>

<ul>
	<li>Members can create, update, and manage their profiles through the website and app.</li>
	<li>The listed business profiles must comply with YEA&rsquo;s guidelines and ethical standards.</li>
	<li>YEA reserves the right to modify or remove any content that violates these terms.</li>
</ul>

<p><strong>4. Payments and Transactions</strong></p>

<ul>
	<li>Any payments made for membership or business listings are non-refundable unless otherwise stated.</li>
	<li>You are responsible for all fees associated with your use of our services.</li>
</ul>

<p><strong>5. Intellectual Property</strong></p>

<p>All content, designs, trademarks, and intellectual property on the YEA website and app are owned by YEA or its licensors. You may not reproduce, distribute, or otherwise use any content without our express written permission.</p>

<p><strong>6. Prohibited Activities</strong></p>

<p>You agree not to:</p>

<ul>
	<li>Use the services for any fraudulent or unlawful purposes.</li>
	<li>Post or transmit content that is defamatory, obscene, or offensive.</li>
	<li>Attempt to interfere with the functionality or security of our website or app.</li>
</ul>

<p><strong>7. Termination</strong></p>

<p>YEA reserves the right to suspend or terminate your account or access to our services if you violate these Terms &amp; Conditions.</p>

<p><strong>8. Limitation of Liability</strong></p>

<p>To the fullest extent permitted by law, YEA will not be liable for any damages or losses arising out of your use of the website or app, including but not limited to data loss or service disruptions.</p>

<p><strong>9. Indemnification</strong></p>

<p>You agree to indemnify and hold YEA harmless from any claims, damages, or liabilities resulting from your breach of these terms or any unlawful activity conducted through your account.</p>

<p><strong>10. Changes to the Terms</strong></p>

<p>YEA reserves the right to modify these Terms &amp; Conditions at any time. Any changes will be communicated, and continued use of the services indicates acceptance of the updated terms.</p>

<p><strong>11. Governing Law</strong></p>

<p>These Terms &amp; Conditions shall be governed by the laws of state of Telangana. Any disputes arising under these terms will be resolved in the courts located in telangana.</p>

<p><strong>12. Contact Us</strong></p>

<p>For any questions or concerns regarding these Terms &amp; Conditions, please contact us at:</p>

<p><strong>YEA India</strong><br />
Email: info@yeaindia.org<br />
Phone: +91 99668 80808</p>

              </div>
            </div>
          </div>
        </div>
       
      </div>
    
    </div>
  </section>



			
    
      <Footer />
   </>
);
}

export default TermsConditions;