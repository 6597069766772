import Breadcrumb from './Breadcumb';
import NewsListing from './NewsListing';

import Header from './Header';
import Footer from './Footer';

function News()
{
return (
<>
<Header />
<Breadcrumb title="Press & Media" />

<NewsListing />
<Footer />

   </>
);
}

export default News;