import axios from "axios";

export const getBanners = async () => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/banners/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addBanner = async (banner) => {
  try {
    const res = await axios.post("https://yeaapi.mydigitalworks.online/banners/create/", banner);
    return res.data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
};

export const updateBanner = async (banner, bannerId) => {
  try {
    const res = await axios.put(
      "https://yeaapi.mydigitalworks.online/banners/update/" + bannerId,
      banner
    );
    return res.data;
  } catch (err) {
    return {
      error: err,
    };
  }
};
export const deleteBanner = async (bannerId) => {
  try {
    
    const res = await axios.delete(
      "https://yeaapi.mydigitalworks.online/banners/delete/" + bannerId
    );
    return res.data;
  } catch (err) {
    return { error: err };
  }
};
export const getBannerById = async (id) => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/banners/" + id);
    return res.data
  } catch (err) {
    return {error: err.message}
  }
};


export const uploadBannerThumbnail = async (formData) => {
  try {
    const res = await axios.post(
      "https://yeaapi.mydigitalworks.online/banners/thumbnailupload/",
      formData
    );
    return res.data
      
  } catch (err) {
    console.log(err);
    return {error: err.message};
  }
};