import axios from "axios";

export const memberVerify = async (member) => {
  try {
    const res = await axios.post("https://yeaapi.mydigitalworks.online/member/verify/", member);
    return res.data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
};

export const getMembers = async () => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/members/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getMembersByMemberType = async (memberType) => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/members/membersbymembertype/?membertype=" + memberType);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};



export const addMember = async (member) => {
  try {
    const res = await axios.post("https://yeaapi.mydigitalworks.online/members/create/", member);
    return res.data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
};

export const updateMember = async (member, memberId) => {
  try {
    const res = await axios.put(
      "https://yeaapi.mydigitalworks.online/members/update/" + memberId,
      member
    );
    return res.data;
  } catch (err) {
    return {
      error: err,
    };
  }
};
export const deleteMember = async (memberId) => {
  try {
    
    const res = await axios.delete(
      "https://yeaapi.mydigitalworks.online/members/delete/" + memberId
    );
    return res.data;
  } catch (err) {
    return { error: err };
  }
};
export const getMemberById = async (id) => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/members/" + id);
    return res.data
  } catch (err) {
    return {error: err.message}
  }
};


export const uploadMemberThumbnail = async (formData) => {
  try {
    const res = await axios.post(
      "https://yeaapi.mydigitalworks.online/members/thumbnailupload/",
      formData
    );
    return res.data
      
  } catch (err) {
    console.log(err);
    return {error: err.message};
  }
};