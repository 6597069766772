import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { getBusinessById } from "../services/business.service";
import Breadcrumb from './Breadcumb';
import Header from './Header';
import Footer from './Footer';

const BASE_API_URL = "https://yeaapi.mydigitalworks.online";

function BusinessDetail()
{


  const { id } = useParams();

  const [businessData, setBusinessData] = useState({});

  useEffect(() => {
    const getBusiness = async () => {
      const businessData  = await getBusinessById(id);
      if(businessData.data[0])
        {      
      setBusinessData(businessData.data[0]);
    }else
    {
      window.location.href="/businesses";
    }
    };
    getBusiness();
  }, [id]);
  
return (
<>
<Header />
<Breadcrumb title={businessData.business_name} />


        <section className="gray py-5 position-relative">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                {/* About The Business */}
                <div className="bg-white rounded mb-4">
                  <div className="jbd-01 px-4 py-4">
                    <div className="jbd-details">
                      <h5 className="ft-bold fs-lg mb-3 text-center">{businessData.business_name}</h5>
                      <img className="" alt="{businessData.business_name}" src={BASE_API_URL + '/uploads/businesses/' + businessData.thumbnailImage} />
                      <div className="d-block mt-3">
                      <p dangerouslySetInnerHTML={{ __html: businessData.description }}>
                      </p>

                      <div className="author-wrap-caption-ngh">
									<div className="author-wrap-jhyu-ngh">
										<ul>
                      { businessData.facebook_profile_URL && (
											<li>
												<div className="gdup-kvty">
													<div className="gdup-kvty-icon bg-light-sky"><a href={businessData.facebook_profile_URL} target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a></div>
												</div>
											</li>
                      )}
                      { businessData.twitter_profile_URL && (
											<li>
												<div className="gdup-kvty">
													<div className="gdup-kvty-icon bg-light-sky"><a href={businessData.twitter_profile_URL} target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a></div>
												</div>
											</li>
                      )}
                      { businessData.linkedin_profile_URL && (
											<li>
												<div className="gdup-kvty">
													<div className="gdup-kvty-icon bg-light-sky"><a href={businessData.linkedin_profile_URL} target="_blank" rel="noreferrer"><i className="fab fa-linkedin"></i></a></div>
												</div>
											</li>
                      )}
                      { businessData.instagram_profile_URL && (
											<li>
												<div className="gdup-kvty">
													<div className="gdup-kvty-icon bg-light-sky"><a href={businessData.instagram_profile_URL} target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a></div>
												</div>
											</li>
                      )}

										</ul>
									</div>
								
								</div>
                      </div>
                    </div>
                  </div>
                </div>
              
             
              
              </div>
              {/* Sidebar */}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                
                <div className="jb-apply-form bg-white rounded py-4 px-4 box-static mb-4">
                  <div className="uli-list-info">
                    <ul>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-user" /></div>
                          <div className="list-uiyt-capt"><h5>Owner/Founder Name</h5><p>{businessData.founder_name}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-industry" /></div>
                          <div className="list-uiyt-capt"><h5>Industry/Sector</h5><p>{businessData.name}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-calendar-check" /></div>
                          <div className="list-uiyt-capt"><h5>Year Established</h5><p>{businessData.year_established}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-users" /></div>
                          <div className="list-uiyt-capt"><h5>Number of Employees</h5><p>{businessData.no_of_employees}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-money-bill" /></div>
                          <div className="list-uiyt-capt"><h5>Annual Revenue</h5><p>{businessData.annual_revenue}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-book" /></div>
                          <div className="list-uiyt-capt"><h5>Business Type</h5><p>{businessData.business_type}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-building" /></div>
                          <div className="list-uiyt-capt"><h5>Target Market</h5><p>{businessData.target_market}</p></div>
                        </div>
                      </li>
                      
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fas fa-globe" /></div>
                          <div className="list-uiyt-capt"><h5>Website URL</h5><p>{businessData.website}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fas fa-envelope" /></div>
                          <div className="list-uiyt-capt"><h5>Email</h5><p>{businessData.email}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fas fa-phone" /></div>
                          <div className="list-uiyt-capt"><h5>Phone</h5><p>{businessData.office_phone}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fas fa-map-marker-alt" /></div>
                          <div className="list-uiyt-capt"><h5>Address</h5><p>{businessData.address}, {businessData.city}, {businessData.state} - {businessData.postal_code}</p></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </section>
        <Footer />

   </>
);
}

export default BusinessDetail;