import Breadcrumb from './Breadcumb';
import MembersListing from './MembersListing';
import Header from './Header';
import Footer from './Footer';
function Members()
{
return (
<>
<Header />
<Breadcrumb title="Members"/>

<MembersListing  memberType="Member"  sectionTitle="YEA Members"/>
<Footer />
   </>
);
}

export default Members;