import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { getEventById } from "../services/event.service";
import Breadcrumb from './Breadcumb';
import Header from './Header';
import Footer from './Footer';

const BASE_API_URL = "https://yeaapi.mydigitalworks.online";

function EventInfo()
{

  const { id } = useParams();

  const [eventData, setEventData] = useState({});

  useEffect(() => {
    const getEvent = async () => {
      const eventData  = await getEventById(id);      
      setEventData(eventData.data[0]);
    };
    getEvent();
  }, [id]);
  
return (
<>
<Header />
<Breadcrumb title={eventData.title} />


        
        <section className="gray py-5 position-relative">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                {/* About The Event */}
                <div className="bg-white rounded mb-4">
                  <div className="jbd-01 px-4 py-4">
                    <div className="jbd-details">
                      <h5 className="ft-bold fs-lg text-center">{eventData.title}</h5>
                      <img src={BASE_API_URL + '/uploads/events/' + eventData.thumbnailImage} className="img-fluid" alt={eventData.title} />
                      <div className="d-block mt-3">
                      <p dangerouslySetInnerHTML={{ __html: eventData.description }}>
                      </p>
                      </div>
                    </div>
                  </div>
                </div>
              
             
              
              </div>
              {/* Sidebar */}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                
                <div className="jb-apply-form bg-white rounded py-4 px-4 box-static mb-4">
                  <div className="uli-list-info">
                    <ul>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-calendar-check" /></div>
                          <div className="list-uiyt-capt"><h5>Start Date</h5><p>{eventData.start_date}</p></div>
                        </div>
                      </li>
                      { eventData.end_date && (
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-industry" /></div>
                          <div className="list-uiyt-capt"><h5>End Date</h5><p>{eventData.end_date}</p></div>
                        </div>
                      </li>
                      )}
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-calendar-check" /></div>
                          <div className="list-uiyt-capt"><h5>Event Type</h5><p>{eventData.eventtypeName}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-users" /></div>
                          <div className="list-uiyt-capt"><h5>Industry</h5><p>{eventData.industryName}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="list-uiyt">
                          <div className="list-iobk"><i className="fa fa-money-bill" /></div>
                          <div className="list-uiyt-capt"><h5>Event Status</h5><p>{eventData.eventStatus}</p></div>
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </section>
        
      <Footer />

   </>
);
}

export default EventInfo;