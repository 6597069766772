import React, { useState, useEffect, useContext } from 'react'
import { getMembersByMemberType } from "../services/member.service";
const BASE_API_URL = "https://yeaapi.mydigitalworks.online";

const MembersListing = ( {memberType, sectionTitle} ) => {

  const [members, setMembers] = useState([]);


  useEffect(() => {
    const fetchMembers = async () => {
      const { data, error } = await getMembersByMemberType(memberType);      
      if (error) {
        console.log(error);
      } else {
        setMembers(data);
      }
    };
    fetchMembers();
  }, [memberType]);

return (
<>
<section className="ptb-60 team-two-section gray">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-md-8 col-lg-8">
      <div className="section-heading text-center mb-5">
        <strong className="color-secondary">Our Team</strong>
        <h2>{sectionTitle}</h2>
        <span className="animate-border mr-auto m-auto mb-4" />
      </div>
    </div>
  </div>
  <div className="row justify-content-center d-flex"> 
  {members.map((data, index) => ( 
  <div className="col-md-3 col-lg-3 col-6 d-flex" key={index}>
      <div className="staff-member card my-2 w-100">
        <div className="text-center">
          <div className="card-thumb">
            <img src={BASE_API_URL + '/uploads/members/' + data.profileImage} alt={data.full_name} className="img-fluid circle" />
          </div>
          <div className="card-body">
            <h5 className="teacher mb-0"><a href={"/member/" + data.memberID}>{data.full_name}</a></h5>
            <span>{data.designation}</span>
            <p className="teacher-quote pt-3">{data.company_name}</p>
            
          </div>
        </div>
        <div className="overlay d-flex align-items-center justify-content-center">
          <div className="overlay-inner">
            <a href="#" className="teacher-name">
              <h5 className="mb-0 teacher text-white">{data.full_name}</h5>
            </a>
            <span className="teacher-field text-white">{data.designation}</span>
            <p className="teacher-quote mt-2">{data.company_name} </p>
            
            <p className="teacher-see-profile">
              <a href={"/member/" + data.memberID} className="btn outline-white-btn">View Profile</a>
            </p>
          </div>
        </div>
      </div>
    </div> ))} </div>
</div>
</section>
</>
);
}

export default MembersListing;