import React, { useState, useEffect } from 'react'
import { getMemberById } from "../../services/member.service";
const BASE_API_URL = "https://yeaapi.mydigitalworks.online";

function DashboardHeader()
{

  useEffect(() => {
    let member_login_status = localStorage.getItem('member_login_status');
    let memberID = localStorage.getItem('memberID');
    if(member_login_status === 0 || member_login_status === null || memberID === 0)
    {
      window.location.href="/signin";
      }
      
  }, []);

  const [memberData, setMemberData] = useState({});

  let memberID = localStorage.getItem('memberID');

  useEffect(() => {
    const getMember = async () => {
      const memberData  = await getMemberById(memberID);      
      setMemberData(memberData.data[0]);
    };
    getMember();
  }, [memberID]);

return (
<>


<section className="bg-cover position-relative pt-120 pb-60" style={{background: 'red url(/assets/images/Head-bg-img-1.jpg) no-repeat'}} data-overlay={3}>
        
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="dashboard-head-author-clicl">
                <div className="dashboard-head-author-thumb">
                  <img src={BASE_API_URL + "/uploads/members/" + memberData.profileImage} className="img-fluid" alt="{memberData.full_name}" />
                </div>
                <div className="dashboard-head-author-caption">
                  <div className="dashploio"><h4>{memberData.full_name}</h4></div>
                  <div className="dashploio"><p className="agd-location"><i className="fa fa-address-book me-1" />{memberData.designation}</p></div>
                  <div className="dashploio"><p className="agd-location"><i className="fa fa-building me-1" />{memberData.company_name}</p></div>
                  <div className="listing-rating high">{ memberData.facebook_profile_URL && (<a href={memberData.facebook_profile_URL} target="_blank" rel="noreferrer" className='px-2'><i className="fab fa-facebook-f active"></i></a>)}{ memberData.twitter_profile_URL && (<a href={memberData.twitter_profile_URL} target="_blank" rel="noreferrer" className='px-2'><i className="fab fa-twitter active"></i></a>)}{ memberData.linkedin_profile_URL && (<a href={memberData.linkedin_profile_URL} target="_blank" rel="noreferrer" className='px-2'><i className="fab fa-linkedin active"></i></a>)}{ memberData.instagram_profile_URL && (<a href={memberData.instagram_profile_URL} target="_blank" rel="noreferrer" className='px-2'><i className="fab fa-instagram active"></i></a>)}</div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <ul className='list-inline text-center'>
            <li><a href="/dashboard-add-business" className='text-white border p-2 rounded d-inline-block mb-2'><i className="lni lni-add-files" />Add Business</a></li>
                
                <li><a href="/dashboard-my-profile-edit" className='text-white border p-2 rounded d-inline-block mb-2'><i className="fa fa-edit" />Edit Profile</a> </li>

                <li><a href="/logout" className='text-white border p-2 rounded d-inline-block mb-2'><i className="lni lni-power-switch" />Log Out</a></li>
              </ul>
              </div>
          </div>
        </div>
      </section>
    

   </>
);
}

export default DashboardHeader;