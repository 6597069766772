import React, { useState, useEffect } from 'react';
import Breadcrumb from './Breadcumb';
import Header from './Header';
import Footer from './Footer';
import { getAppInfo } from "../services/appInfo.service";

function ContactUs()
{
  const { id } = 1;
  const [appData, setAppInfo] = useState([]);

  useEffect(() => {
    const getAppInfoData = async () => {
      const AppInfo  = await getAppInfo(id);      
      setAppInfo(AppInfo.data[0]);
    };
    getAppInfoData(id);
  }, []);



return (
<>

<Header />
<Breadcrumb title="Contact Us" />
<section className="gray ptb-60">
				<div className="container">
				
					
					<div className="row align-items-start justify-content-center">
						
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
							<form className="row submit-form py-4 px-3 rounded bg-white mb-4">
									
								<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
									<div className="form-group">
										<label className="small text-dark ft-medium">Your Name *</label>
										<input type="text" className="form-control" value="Your Name" />
									</div>
								</div>
								
								<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
									<div className="form-group">
										<label className="small text-dark ft-medium">Your Email *</label>
										<input type="text" className="form-control" value="Your Email" />
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
									<div className="form-group">
										<label className="small text-dark ft-medium">Mobile</label>
										<input type="text" className="form-control" value="+91 1256 548 7542" />
									</div>
								</div>
								<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
									<div className="form-group">
										<label className="small text-dark ft-medium">Subject</label>
										<input type="text" className="form-control" value="Type Your Subject" />
									</div>
								</div>
								
								<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
									<div className="form-group">
										<label className="small text-dark ft-medium">Message</label>
										<textarea className="form-control ht-80">Your Message...</textarea>
									</div>
								</div>
								
								<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
									<div className="form-group">
										<button type="button" className="btn theme-bg text-light">Send Message</button>
									</div>
								</div>
								
							</form>
						</div>
						
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
						<ul id="eael-feature-list-3896a76" className="eael-feature-list-items rhombus stacked connector-type-classic">
        <li className="eael-feature-list-item elementor-repeater-item-9535018">
          
          <div className="eael-feature-list-icon-box">
            <div className="eael-feature-list-icon-inner">
              <span className="eael-feature-list-icon fl-icon-0">
                <i aria-hidden="true" className="fas fa-map-marked-alt" />								</span>
            </div>
          </div>
          <div className="eael-feature-list-content-box">
            <h6 className="eael-feature-list-title">Address</h6>
            <p className="eael-feature-list-content">{appData.address}</p>
          </div>
        </li>
        <li className="eael-feature-list-item elementor-repeater-item-6965c31">
          
          <div className="eael-feature-list-icon-box">
            <div className="eael-feature-list-icon-inner">
              <span className="eael-feature-list-icon fl-icon-1">
                <i aria-hidden="true" className="far fa-envelope-open" />								</span>
            </div>
          </div>
          <div className="eael-feature-list-content-box">
            <h6 className="eael-feature-list-title">E-mail ID</h6>
            <p className="eael-feature-list-content">{appData.email}</p>
          </div>
        </li>
        <li className="eael-feature-list-item elementor-repeater-item-c8ba242">
          
          <div className="eael-feature-list-icon-box">
            <div className="eael-feature-list-icon-inner">
              <span className="eael-feature-list-icon fl-icon-2">
                <i aria-hidden="true" className="fas fa-phone-alt" />								</span>
            </div>
          </div>
          <div className="eael-feature-list-content-box">
            <h6 className="eael-feature-list-title">Contact Number</h6>
            <p className="eael-feature-list-content">{appData.phone}</p>
          </div>
        </li>
        <li className="eael-feature-list-item elementor-repeater-item-04faa96">
          
          <div className="eael-feature-list-icon-box">
            <div className="eael-feature-list-icon-inner">
              <span className="eael-feature-list-icon fl-icon-3">
                <i aria-hidden="true" className="fas fa-clock" />								</span>
            </div>
          </div>
          <div className="eael-feature-list-content-box">
            <h6 className="eael-feature-list-title">Opening Hours</h6>
            <p className="eael-feature-list-content">Opens Daily 10am–5pm <br />Sunday Until 8pm.</p>
          </div>
        </li>
      </ul>
						</div>
						
					</div>
				</div>
			</section>
      <Footer />
   </>
);
}

export default ContactUs;