import axios from "axios";

export const getEvents = async () => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/events/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addEvent = async (event) => {
  try {
    const res = await axios.post("https://yeaapi.mydigitalworks.online/events/create/", event);
    return res.data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
};

export const updateEvent = async (event, eventId) => {
  try {
    const res = await axios.put(
      "https://yeaapi.mydigitalworks.online/events/update/" + eventId,
      event
    );
    return res.data;
  } catch (err) {
    return {
      error: err,
    };
  }
};
export const deleteEvent = async (eventId) => {
  try {
    
    const res = await axios.delete(
      "https://yeaapi.mydigitalworks.online/events/delete/" + eventId
    );
    return res.data;
  } catch (err) {
    return { error: err };
  }
};
export const getEventById = async (id) => {
  try {
    const res = await axios.get("https://yeaapi.mydigitalworks.online/events/" + id);
    return res.data
  } catch (err) {
    return {error: err.message}
  }
};


export const uploadEventThumbnail = async (formData) => {
  try {
    const res = await axios.post(
      "https://yeaapi.mydigitalworks.online/events/thumbnailupload/",
      formData
    );
    return res.data
      
  } catch (err) {
    console.log(err);
    return {error: err.message};
  }
};