import React, { useState, useEffect } from 'react';
import Breadcrumb from './Breadcumb';
import MembersListing from './MembersListing';
import { getAboutSection1, getAboutSection2, getAboutSection3, getAboutSection4 } from "../services/about.service";
import Header from './Header';
import Footer from './Footer';

const BASE_API_URL = "https://yeaapi.mydigitalworks.online";
function AboutUs()
{

  const [AboutSection1, setAboutSection1] = useState([]);
  useEffect(() => {
    const fetchAboutSection1 = async () => {
      const { data, error } = await getAboutSection1();
      if (error) {
        console.log(error);
      } else {
        setAboutSection1(data);
      }
    }
    fetchAboutSection1();
  }, []);
  
  const [AboutSection2, setAboutSection2] = useState([]);
  useEffect(() => {
    const fetchAboutSection2 = async () => {
      const { data, error } = await getAboutSection2();
      if (error) {
        console.log(error);
      } else {
        setAboutSection2(data);
      }
    }
    fetchAboutSection2();
  }, []);

  const [AboutSection3, setAboutSection3] = useState([]);
  useEffect(() => {
    const fetchAboutSection3 = async () => {
      const { data, error } = await getAboutSection3();
      if (error) {
        console.log(error);
      } else {
        setAboutSection3(data);
      }
    }
    fetchAboutSection3();
  }, []);


  const [AboutSection4, setAboutSection4] = useState([]);
  useEffect(() => {
    const fetchAboutSection4 = async () => {
      const { data, error } = await getAboutSection4();
      if (error) {
        console.log(error);
      } else {
        setAboutSection4(data);
      }
    }
    fetchAboutSection4();
  }, []);


 

return (
<>
<Header />
<Breadcrumb title="About Us" />

<section className="ptb-30 gray">
    <div className="container">
    { AboutSection1.map((data, index) => ( 
      <div key={index} className="row align-items-center justify-content-between">
        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
          <div className="content_block_2 pr-3 py-4">
            <div className="content-box">
              <div className="sec-title light">
                <h2 className="ft-bold">{data.title}</h2>
                <span className="animate-border mb-3"></span>
              </div>
              <div className="text mb-3">
                <p>{data.description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12 image-column">
          <div className="image-box">
            <figure className="image bx-shadow">
            <img src={BASE_API_URL + '/uploads/about/' + data.thumbnailImage} alt={data.title} className="img-fluid rounded" />
            </figure>
          </div>
        </div>
      </div>
       ))}
    </div>
  </section>


  <section className="space">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <div className="position-relative">
                <img src="assets/images/Section-2-about-us-img-1.jpg" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">	
              <div className="m-spaced">
                
                <div className="uli-list-features">
                  <ul>
                  { AboutSection2.map((data, index) => ( 
                    <li key={index}>
                      <div className="list-uiyt">
                        <div className="list-iobk"><span className="eael-feature-list-icon fl-icon-0">
                          <img src={BASE_API_URL + '/uploads/about/' + data.thumbnailImage} alt={data.title} className="img-fluid rounded" />
                          </span></div>
                        <div className="list-uiyt-capt">
                          <h5>{data.title}</h5>
                          <p>{data.description}</p>
                        </div>
                      </div>
                    </li>
                    ))}

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>





<section className="bg-dark ptb-60">
    <div className="container">
    { AboutSection3.map((data, index) => ( 
      <div key={index} className="row justify-content-center">	
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-auto">
          <div className="wrk-pro-box first bg-dark">
            
            <div className="wrk-pro-box-caption">
              <h2 className="text-white">{data.title}</h2>
			  <span className="animate-border mb-3"></span>
              <p className="text-white fw-400">{data.description}</p>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex my-auto">
          <img src={BASE_API_URL + '/uploads/about/' + data.thumbnailImage} alt={data.title} className="img-fluid rounded" />
        </div>

		
      </div>
      ))}
    </div>
  </section>

  <MembersListing memberType="Board Member"  sectionTitle="Meet our distinguished Board Members"/>

<section className="space">
      <div className="container">
      { AboutSection4.map((data, index) => ( 
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">

    <div className="sec-title light text-center my-auto">
              <h2 className="ft-bold">{data.title}</h2>
              <span className="animate-border mb-3 m-auto"></span>
      <p>{data.description}</p>
            </div>

            <div className="position-relative">
              <img src={BASE_API_URL + '/uploads/about/' + data.thumbnailImage} alt={data.title} className="img-fluid" />
            </div>
          </div>
          
        </div>
        ))}
      </div>
    </section>


  

    <MembersListing memberType="Advisory Member"  sectionTitle="Advisory Members"/>


			
    
      <Footer />
   </>
);
}

export default AboutUs;