import axios from "axios";


//===========//

export const getAppInfo = async () => {
    try {
      const res = await axios.get("https://yeaapi.mydigitalworks.online/appinfo/");
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
  

  