import React from 'react';
import Breadcrumb from './Breadcumb';

import Header from './Header';
import Footer from './Footer';

function PrivacyPolicy()
{

  
 

return (
<>
<Header />
<Breadcrumb title="Privacy Policy" />

<section className="ptb-30 gray">
    <div className="container">
   
      <div className="row align-items-center justify-content-between">
        <div className="col-lg-12 col-md-12 col-sm-12 content-column">
          <div className="content_block_2 pr-3 py-4">
            <div className="content-box">
              <div className="sec-title light">
                <h2 className="ft-bold">Privacy Policy</h2>
                <span className="animate-border mb-3"></span>
              </div>
              <div className="text mb-3 list-style-disc">
             
              <p><strong>Effective Date:</strong> 15/10/24</p>

<p><strong>YEA Privacy Policy</strong></p>

<p>At YEA, we are committed to protecting your personal information and ensuring your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our website, mobile application, and related services. By accessing or using our services, you agree to the terms of this Privacy Policy.</p>

<p><strong>1. Information We Collect</strong></p>

<ul>
	<li><strong>Personal Data:</strong> When you register or interact with our services, we may collect personal data such as your name, email address, contact number, business details, and profile information.</li>
	<li><strong>Automatically Collected Data:</strong> We may also collect information such as your IP address, browser type, device information, and usage data through cookies and similar technologies.</li>
</ul>

<p><strong>2. How We Use Your Information</strong></p>

<ul>
	<li>To manage and improve our website and app.</li>
	<li>To personalize user experience based on preferences.</li>
	<li>To process transactions, including membership or business profile updates.</li>
	<li>To communicate important information such as changes to our services, updates, or promotions.</li>
	<li>To comply with legal obligations or respond to legal requests.</li>
</ul>

<p><strong>3. Sharing of Information</strong></p>

<p>We do not sell, trade, or rent your personal data. We may share your information with:</p>

<ul>
	<li><strong>Service Providers:</strong> Third-party vendors helping us with hosting, data analysis, and customer service.</li>
	<li><strong>Legal Compliance:</strong> In cases where disclosure is required by law, court orders, or to protect our legal rights.</li>
</ul>

<p><strong>4. Security of Your Data</strong></p>

<p>We implement industry-standard security measures to protect your personal data. However, please note that no online data transmission is completely secure, and we cannot guarantee absolute protection.</p>

<p><strong>5. Your Privacy Rights</strong></p>

<ul>
	<li><strong>Access and Update:</strong> You can access, modify, or delete your personal data by contacting our support team.</li>
	<li><strong>Opt-Out:</strong> You may opt-out of receiving marketing communications from us at any time.</li>
</ul>

<p><strong>6. Children&rsquo;s Privacy</strong></p>

<p>Our services are not directed to children under the age of 13. We do not knowingly collect personal data from children under this age. If we become aware of such collection, we will promptly delete the information.</p>

<p><strong>7. Changes to This Privacy Policy</strong></p>

<p>We reserve the right to modify this Privacy Policy at any time. Any changes will be reflected here, and your continued use of the services constitutes acceptance of the changes.</p>

<p><strong>8. Contact Us</strong></p>

<p>If you have any questions about this Privacy Policy or wish to exercise your rights, please contact us at:</p>

<p><strong>YEA India</strong><br />
Email: info@yeaindia.org<br />
Phone: +91 99668 80808</p>




              </div>
            </div>
          </div>
        </div>
       
      </div>
    
    </div>
  </section>



			
    
      <Footer />
   </>
);
}

export default PrivacyPolicy;