import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";

import DashboardHeader from "./DashboardHeader";
import { getIndustries } from "../../services/industry.service";
import { updateBusiness, uploadBusinessThumbnail, getBusinessById } from "../../services/business.service";
import Header from '../Header';
import Footer from '../Footer';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const BASE_API_URL = "https://yeaapi.mydigitalworks.online";


function DashboardMyBusinessEdit()
{
  let memberID = localStorage.getItem('memberID');
  const { id } = useParams();
  const [industries, setIndustries] = useState([]);
  useEffect(() => {
    const fetchIndustries = async () => {
      const { data, error } = await getIndustries();
      if (error) {
        console.log(error);
      } else {
        setIndustries(data);
      }
    };
    fetchIndustries();
  }, []);

  const [defaultValue, setBusinessData] = useState({
    business_name: "",
founder_name: "",
industryID: "",
description: "",
no_of_employees: "",
annual_revenue: "",
business_type: "",
target_market: "",
address: "",
city: "",
state: "",
postal_code: "",
office_phone: "",
email: "",
website: "",
year_established: "",
thumbnailImage: "",
facebook_profile_URL: "",
twitter_profile_URL: "",
linkedin_profile_URL: "",
instagram_profile_URL: ""
  });



  useEffect(() => {
    const getBusiness = async () => {
      const { data } = await getBusinessById(id);
      if (data) setBusinessData({ ...data[0] });
    };
    getBusiness();
  }, [id]);



  const {
    business_name,
founder_name,
industryID,
description,
no_of_employees,
annual_revenue,
business_type,
target_market,
address,
city,
state,
postal_code,
office_phone,
email,
website,
year_established,
thumbnailImage,
facebook_profile_URL,
twitter_profile_URL,
linkedin_profile_URL,
instagram_profile_URL,
  } = defaultValue;

  const [textEditor, setTextEditor] = useState("");

  const [selectedImage, setSelectedImage] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  
  useEffect(() => {
    let url;
    if (selectedImage) {
      url = URL.createObjectURL(selectedImage);
      setPreviewUrl(url);
    }
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [selectedImage]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e.target);
    let formData = new FormData(e.target);
    let fileFormData = new FormData();
    let files = e.target[15].files;
    formData.append("description",textEditor);
    const values = Object.fromEntries(formData.entries());
    const imgName = Math.round((new Date()).getTime() / 1000) + values.business_name.toLowerCase().replaceAll(/[\s\t]+/g, "-");
    fileFormData.append("imgName", imgName);
    delete values.thumbnailImage;

      if (!!selectedImage) {
        fileFormData.append("thumbnailImage", files[0]);
        let { data, error } = await uploadBusinessThumbnail(fileFormData);
        values["thumbnailImage"] = data;
      }
      if (values.thumbnailImage) {
        let formValues = {
          ...values,
          thumbnailImage: values.thumbnailImage,
          memberID: memberID
        };

        let { data, error } = await updateBusiness(values, id);
        if (error) throw new Error(error);
      }else
      {
        let formValues = {
          ...values,
          memberID: memberID
        };
        let { data, error } = await updateBusiness(values, id);
        if (error) throw new Error(error);
      }
      window.location.href="/dashboard-my-businesses";
  };

return (
<>
<Header />
<DashboardHeader />

<section className="goodup-dashboard-wrap gray px-4 py-5">

        <div className="goodup-dashboard-content">
          <div className="dashboard-tlbar d-block mb-5">
            <div className="row">
              <div className="colxl-12 col-lg-12 col-md-12">
                <h1 className="ft-medium">Edit Business</h1>
               
              </div>
            </div>
          </div>
          <div className="dashboard-widg-bar d-block">
            <div className="row">
              <div className="col-xl-12 col-lg-2 col-md-12 col-sm-12">
                <div className="submit-form">
                  <form onSubmit={handleSubmit} >
                  <div className="dashboard-list-wraps bg-white rounded mb-4">
                    <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                      <div className="dashboard-list-wraps-flx">
                        <h4 className="mb-0 ft-medium fs-md"><i className="fa fa-file me-2 theme-cl fs-sm" />Business Info</h4>	
                      </div>
                    </div>
                    <div className="dashboard-list-wraps-body py-3 px-3">
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Business Name</label>
                            <input type="text" className="form-control rounded" name="business_name" placeholder="Business Name" defaultValue={business_name || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Owner/Founder Name</label>
                            <input type="text" className="form-control rounded" name="founder_name" placeholder="Owner/Founder Name" defaultValue={founder_name || ""} />
                          </div>
                        </div>
                        
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Industry/Sector</label>
                            <select className="form-control" name="industryID">
                              <option value={defaultValue.industryID}>{defaultValue.name}</option>
                              {industries.map((industryData, index) => (
                              <option key = {index} value={industryData.industryID}>{industryData.name}</option>
                            ))}
                            </select>
                          </div>
                        </div>
                        
                        
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Number of Employees</label>
                            <input type="text" className="form-control rounded" placeholder="Number of Employees" name="no_of_employees" defaultValue={no_of_employees || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Annual Revenue</label>
                            <select className="form-control" name="annual_revenue" required>
                            <option value={defaultValue.annual_revenue}>{defaultValue.annual_revenue}</option>
                              <option>0 -25cr</option>
                              <option>25 - 50cr</option>
                              <option>50 - 100cr</option>
                              <option>100+ Crores</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Business Type</label>
                            <input type="text" className="form-control rounded" placeholder="Business Type (e.g., Sole Proprietorship, LLC, Corporation)" name="business_type" defaultValue={business_type || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Target Market/Customer Base</label>
                            <input type="text" className="form-control rounded" placeholder="Target Market/Customer Base" name="target_market" defaultValue={target_market || ""} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Location Info */}
                  <div className="dashboard-list-wraps bg-white rounded mb-4">
                    <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                      <div className="dashboard-list-wraps-flx">
                        <h4 className="mb-0 ft-medium fs-md"><i className="fas fa-map-marker-alt me-2 theme-cl fs-sm" />Business Address</h4>	
                      </div>
                    </div>
                    <div className="dashboard-list-wraps-body py-3 px-3">
                      <div className="row">
                        
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Address</label>
                            <input type="text" className="form-control rounded" placeholder="Address" name="address" defaultValue={address || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">City</label>
                            <input type="text" className="form-control rounded" placeholder="City" name="city" defaultValue={city || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">State</label>
                            <input type="text" className="form-control rounded" placeholder="State" name="state" defaultValue={state || ""} />
                          </div>
                        </div>
                        
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Postal Code</label>
                            <input type="text" className="form-control rounded" placeholder="Postal Code" name="postal_code" defaultValue={postal_code || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Office Phone</label>
                            <input type="text" className="form-control rounded" placeholder="Office Phone" name="office_phone" defaultValue={office_phone || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Email</label>
                            <input type="text" className="form-control rounded" placeholder="Email" name="email" defaultValue={email || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Website</label>
                            <input type="text" className="form-control rounded" placeholder="Website" name="website" defaultValue={website || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Year Established</label>
                            <input type="text" className="form-control rounded" placeholder="Year Established" name="year_established" defaultValue={year_established || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Thumbnail Image</label>
                            <input onChange={(e) => { setSelectedImage(e.target.files[0]); }} accept="image/*" name="thumbnailImage" type={"file"} className="form-control rounded" placeholder="" />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        {(defaultValue.thumbnailImage || previewUrl) && (
              <img
                className="mw-50"
                alt="thumbnail"
                src={
                  previewUrl
                    ? previewUrl
                    : `${BASE_API_URL}/uploads/businesses/${defaultValue.thumbnailImage}`
                }
              />
            )}
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1">Business Description</label>
                            <CKEditor editor={ClassicEditor} data={description} onChange={(event, editor) => { const description = editor.getData(); setTextEditor(description); }} placeholder="Business Description" />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                
                  {/* Social Links */}
                  <div className="dashboard-list-wraps bg-white rounded mb-4">
                    <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                      <div className="dashboard-list-wraps-flx">
                        <h4 className="mb-0 ft-medium fs-md"><i className="fa fa-user-friends me-2 theme-cl fs-sm" />Social Links</h4>	
                      </div>
                    </div>
                    <div className="dashboard-list-wraps-body py-3 px-3">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1"><i className="ti-facebook theme-cl me-1" />Facebook Profile URL</label>
                            <input type="text" className="form-control rounded" placeholder="Facebook Profile URL" name="facebook_profile_URL" defaultValue={facebook_profile_URL || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1"><i className="ti-twitter theme-cl me-1" />Twitter Profile URL</label>
                            <input type="text" className="form-control rounded" placeholder="Twitter Profile URL" name="twitter_profile_URL" defaultValue={twitter_profile_URL || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1"><i className="ti-instagram theme-cl me-1" />Instagram Profile URL</label>
                            <input type="text" className="form-control rounded" placeholder="Instagram Profile URL" name="instagram_profile_URL" defaultValue={instagram_profile_URL || ""} />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="mb-1"><i className="ti-linkedin theme-cl me-1" />Linkedin Profile URL</label>
                            <input type="text" className="form-control rounded" placeholder="Linkedin Profile URL" name="linkedin_profile_URL" defaultValue={linkedin_profile_URL || ""} />
                          </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <button className="btn theme-bg rounded text-light" type="Submit">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </section>
      <Footer />
   </>
);
}

export default DashboardMyBusinessEdit;